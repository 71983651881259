@media screen and (max-width: 900px) {
  .header-logo {
    display: none !important;
  }
}
@media screen and (max-width: 900px) {
  .header-tabs {
    width: 100% !important;
    justify-content: space-between !important;
  }
}
.error-color {
  color: #ec3b3b;
}
.col-66 {
  color: #66B2BB;
}
.col-9b {
  color: #9b9b9b;
}
.bg-trans {
  background: transparent !important;
}
.bg-red {
  background: red;
}
.col-6D {
  color: #6D6D6D;
}
.col-00 {
  color: #000;
}
.col-w {
  color: white;
}
.primary-color {
  color: #29C1B9;
}
.col-48 {
  color: #484848;
}
h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}
h2 {
  font-weight: 700 ;
  font-size: 24px ;
  line-height: 36px;
}
h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.fz-12 {
  font-size: 12px;
}
.fz-14 {
  font-size: 14px;
}
.fz-16 {
  font-size: 16px;
}
.fz-18 {
  font-size: 18px;
}
.fz-24 {
  font-size: 24px;
}
.fz-32 {
  font-size: 32px;
}
.fz-40 {
  font-size: 40px;
}
strong {
  font-weight: 700;
}
.bold {
  font-weight: 700;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.ellipsis {
  text-overflow: ellipsis;
}
html,
body {
  overflow: auto;
  font-family: NotoSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}
.amp {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
}
u {
  text-underline-offset: 2px;
}
.ant-input {
  border-radius: 4px;
  font-size: 14px !important;
}
.main-wrapper {
  box-sizing: border-box;
}
#router-content {
  padding: 12px 50px 0px 50px;
  box-sizing: border-box;
  margin-top: 68px;
}
.full-content {
  margin: -12px -50px -32px -50px;
}
.full-content .ant-menu-inline {
  height: calc(100% - 6vh);
}
.full-content .ant-menu-sub.ant-menu-inline {
  background: #fff !important;
}
.ant-scrolling-effect {
  width: 100% !important;
}
.side-content {
  padding: 0 50px 0 50px;
  width: 0;
  flex: 1;
}
.side-content .ant-table-body {
  overflow-y: scroll;
}
.side-content .ant-table-body::-webkit-scrollbar {
  display: block;
  width: 8px;
  border-radius: 4px;
}
.header-wrapper {
  position: fixed;
  top: 0;
  z-index: 501;
  width: 100%;
}
.z-index-important {
  z-index: 1000 !important;
}
.ant-image-preview-operations {
  z-index: 2000 !important;
}
.blank-content {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.blank-content h2 {
  color: #c2c2c2;
}
.blank-content img {
  width: 30vw;
  margin-left: -20px;
  margin-bottom: -90px;
}
.amap-container .amap-logo {
  display: none !important;
  height: 0;
  width: 0;
}
.amap-container .amap-copyright {
  display: none !important;
  height: 0;
  width: 0;
}
.mg-l-20 {
  margin-left: 20px;
}
.mg-t-3 {
  margin-top: 3px;
}
.mg-t-6 {
  margin-top: 6px;
}
.mg-t-8 {
  margin-top: 8px;
}
.mg-l-7 {
  margin-left: 7px;
}
.mg-l-8 {
  margin-left: 8px;
}
.amp-mg-l-8 {
  margin-left: 8px;
}
.mg-l-9 {
  margin-left: 9px;
}
.mg-l-16 {
  margin-left: 16px;
}
.mg-l-30 {
  margin-left: 30px;
}
.mg-l-40 {
  margin-left: 40px;
}
.mg-l-50 {
  margin-left: 50px;
}
.mg-t-5 {
  margin-top: 5px;
}
.mg-t-4 {
  margin-top: 4px;
}
.mg-t-12 {
  margin-top: 12px;
}
.mg-t-14 {
  margin-top: 14px;
}
.mg-b-10 {
  margin-bottom: 10px;
}
.mg-l-12 {
  margin-left: 12px;
}
.mg-b-14 {
  margin-bottom: 14px;
}
.mg-b-16 {
  margin-bottom: 16px;
}
.mg-t-17 {
  margin-top: 17px;
}
.mg-r-3 {
  margin-right: 3px;
}
.mg-r-30 {
  margin-right: 30px;
}
.mg-r-17 {
  margin-right: 17px;
}
.mg-r-20 {
  margin-right: 20px;
}
.mg-r-26 {
  margin-right: 26px;
}
.mg-r-10 {
  margin-right: 10px;
}
.mg-r-8 {
  margin-right: 8px;
}
.amp-mg-r-8 {
  margin-right: 8px;
}
.mg-l-10 {
  margin-left: 10px;
}
.mg-l-10-f {
  margin-left: -10px;
}
.mg-r-18 {
  margin-right: 18px;
}
.mg-r-32 {
  margin-right: 32px;
}
.mg-r-34 {
  margin-right: 34px;
}
.mg-b-4 {
  margin-bottom: 4px;
}
.mg-b-42 {
  margin-bottom: 42px;
}
.mg-b-40 {
  margin-bottom: 40px;
}
.mg-b-50 {
  margin-bottom: 50px;
}
.mg-t-42 {
  margin-top: 42px;
}
.mg-b-68 {
  margin-bottom: 68px;
}
.mg-b-6 {
  margin-bottom: 6px;
}
.mg-tb-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mg-tb-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.mg-tb-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.mg-t-16 {
  margin-top: 16px;
}
.mg-t-30 {
  margin-top: 30px;
}
.mg-t-33 {
  margin-top: 33px;
}
.mg-tb-30 {
  margin: 30px 0px;
}
.mg-lr-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.mg-r-140 {
  margin-right: 140px;
}
.mg-b-8 {
  margin-bottom: 8px;
}
.mg-r-12 {
  margin-right: 12px;
}
.mg-r-5 {
  margin-right: 5px;
}
.mg-r-3 {
  margin-right: 3px;
}
.mg-r-4 {
  margin-right: 4px;
}
.mg-r-43 {
  margin-right: 43px;
}
.mg-l-4 {
  margin-left: 4px;
}
.mg-l-14 {
  margin-left: 14px;
}
.mg-r-14 {
  margin-right: 14px;
}
.mg-r-16 {
  margin-right: 16px;
}
.mg-r-6 {
  margin-right: 6px;
}
.mg-r-9 {
  margin-right: 9px;
}
.mg-b-13 {
  margin-bottom: 13px;
}
.mg-b-12 {
  margin-bottom: 12px;
}
.mg-l-16 {
  margin-left: 16px;
}
.mg-l-60 {
  margin-left: 50px;
}
.pd-b-16 {
  padding-bottom: 16px;
}
.pd-b-28 {
  padding-bottom: 28px;
}
.mg-tb-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.mg-b-35 {
  margin-bottom: 35px;
}
.mg-b-30 {
  margin-bottom: 30px;
}
.mg-b-29 {
  margin-bottom: 29px;
}
.mg-b-28 {
  margin-bottom: 28px;
}
.mg-b-20 {
  margin-bottom: 20px;
}
.mg-t-20 {
  margin-top: 20px;
}
.mg-t-22 {
  margin-top: 22px;
}
.mg-lr-26 {
  margin: 0px 26px;
}
.mg-t-60 {
  margin-top: 60px;
}
.mg-b-43 {
  margin-bottom: 43px;
}
.mg-b-22 {
  margin-bottom: 22px;
}
.mg-b-24 {
  margin-bottom: 24px;
}
.mg-b-46 {
  margin-bottom: 46px;
}
.mg-r-22 {
  margin-right: 22px;
}
.mg-r-50 {
  margin-right: 50px;
}
.mg-b-18 {
  margin-bottom: 18px;
}
.mg-t-30h {
  margin-top: 30vh;
}
.mg-r-60 {
  margin-right: 60px;
}
.mg-t-68 {
  margin-top: 68px;
}
::-webkit-scrollbar {
  width: 4px;
  /* 纵向滚动条*/
  height: 4px;
  /* 横向滚动条 */
}
/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: transparent;
  opacity: 0;
  transition: opacity 120ms ease-out;
}
/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #e0e0e0 !important;
  border-radius: 3px;
  background-clip: padding-box;
  transition: 0.3s background-color;
}
::-webkit--corner {
  background-color: transparent;
}
::-webkit-scrollbar-button {
  height: 12px;
}
.rc-virtual-list-scrollbar-thumb {
  background-color: #C2C2C2 !important;
  width: 5px !important;
  border-radius: 10px !important;
}
.rc-virtual-list-scrollbar {
  width: 4px !important;
}
.overflow-hidden {
  overflow: hidden;
}
.display-flex {
  display: flex;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.align-center {
  align-items: center;
  display: flex;
}
.space-between {
  justify-content: space-between;
  display: flex;
}
.space-evenly {
  justify-content: space-evenly;
  display: flex;
}
.space-around {
  justify-content: space-around;
  display: flex;
}
.justify-center {
  justify-content: center;
  display: flex;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.absolute {
  position: absolute;
}
.position-absolute {
  position: absolute;
}
.amps-voidForm .desc-field {
  margin-top: 34px;
}
.amps-voidForm .desc-field .label {
  margin-right: 30px;
  display: inline-block;
  color: #000000;
  font-weight: bold;
}
.amps-voidForm .desc-field .value {
  display: block;
  margin-top: 23px;
  color: #000000;
}
.amps-voidForm .desc-field-flex {
  margin-top: 34px;
  display: flex;
}
.amps-voidForm .desc-field-flex .label {
  margin-right: 30px;
  display: inline-block;
  color: #000000;
  font-weight: bold;
}
.activities {
  height: 95vh;
  overflow: hidden scroll;
  margin-right: -49px;
}
.activities::-webkit-scrollbar {
  display: block;
  width: 8px;
  border-radius: 4px;
}
.activities::-webkit-scrollbar-thumb {
  background-color: #c8c8c8 !important;
}
.activities::-webkit-scrollbar-thumb:hover {
  background: #b6b6b6 !important;
}
.activities-timeline {
  margin-top: 30px;
}
.activities-date {
  font-size: 16px;
  background: #f8f8f8;
  opacity: 0.8;
  border-radius: 2px;
  width: 176px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}
.announcement-page {
  padding: 52px 190px 100px 190px;
  overflow: auto;
  height: 93vh;
  margin-right: -49px;
}
.announcement-page iframe {
  width: 100%;
  height: 72vh;
}
.bund-icon,
.is-bund,
.not-bund {
  border-radius: 28px;
  width: 62px;
  height: 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.is-bund {
  color: #6cc640;
  background: #e7ffdb;
  border: 1px solid #add699;
}
.not-bund {
  color: #c6c6c6;
  background: #f4f4f4;
}
.wechat-background {
  background: #f8fafa;
  margin-top: 30px;
  padding: 40px 50px;
  margin-left: -50px;
  margin-right: -50px;
  box-sizing: border-box;
  height: 80vh;
}
.wechat-option,
.wechat-option-disabled {
  width: 14.635416666666668vw;
  height: 8.020833333333334vw;
  background: #ffffff;
  border: 1px solid #dfe8ed;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 50px;
}
.wechat-option .ant-form-item {
  margin: 0 !important;
}
.wechat-option-card-top {
  height: 5vw;
  background: #7cd7d2;
  border-radius: 4px 4px 0px 0px;
  padding: 36px 20px;
  box-sizing: border-box;
  color: white;
  font-size: 16px;
  cursor: default;
}
.wechat-option-card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  box-sizing: border-box;
  line-height: 18px;
}
.option-line {
  width: 30px;
  height: 4px;
  background-color: white;
  margin-top: 6px;
}
.wechat-option-disabled .wechat-option-card-top {
  background: #dadada;
}
.wechat-option-disabled .ant-form-item {
  margin: 0 !important;
}
.wechat-option-disabled .wechat-option-card-bottom span {
  color: #757575;
}
.spinLocation {
  position: relative;
  top: 50%;
  left: 50%;
}
.asset-water-fall {
  background-color: #000;
  width: 94.584vw;
  overflow-y: scroll;
  height: 82.593vh;
  padding-top: 8px;
}
.asset-water-fall .waterfall-preview-box {
  height: 15.208vw;
  width: 100%;
  margin-bottom: 0.416vw;
}
.asset-water-fall .waterfall-preview-box .render-item {
  height: 100%;
  display: inline-block;
  margin-right: 0.416vw;
  background-color: #0F0F0F;
}
.asset-water-fall .waterfall-preview-box .render-item .ant-image {
  height: 100%;
  width: 100%;
}
.asset-water-fall .waterfall-preview-box .render-item .ant-image img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.asset-water-fall .waterfall-preview-box .render-item span {
  color: #484848;
  z-index: 20;
  position: relative;
  float: left;
  bottom: 28px;
  font-size: 12px;
  margin: 0 8px;
  display: none;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.asset-water-fall .waterfall-preview-box .render-item span:last-child {
  width: 110px;
  text-align: right;
  float: right;
}
.asset-water-fall .waterfall-preview-box .render-item span:hover {
  color: #29C1B9;
  cursor: pointer;
}
.asset-water-fall .waterfall-preview-box .render-item:hover {
  border: 1px solid #3C3C3C;
}
.asset-water-fall .waterfall-preview-box .render-item:hover span {
  display: inline-block;
}
.asset-water-fall .waterfall-preview-box :last-child {
  margin-right: 0;
}
.asset-water-fall .ant-empty-description {
  color: #D7D7D7;
  font-family: Noto Sans SC;
}
.editAsset {
  overflow-y: scroll;
}
.editAsset .ant-modal-body {
  height: 548px;
}
.assets-update-box {
  margin: 12px 28px 24px 28px;
}
.assets-update-title {
  font-size: 32px;
  font-weight: 700;
}
.asset-update-category {
  width: 260px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  margin-left: 16px;
  box-sizing: border-box;
}
.asset-update-category .ant-select-selection-search {
  margin-left: 10px!important;
}
.asset-update-category .ant-select-selection-item {
  margin-left: 10px!important;
}
.asset-update-footer {
  margin-top: 68px;
  text-align: center;
}
.asset-update-footer button {
  width: 120px;
  height: 40px;
  border: none;
  box-sizing: border-box;
  padding: 0;
  font-size: 14px;
  font-family: Noto Sans SC;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
.asset-update-footer :first-child {
  background-color: #29C1B9;
  color: #FFF;
}
.asset-update-footer :first-child:hover {
  background-color: #4ccfc4;
  border-color: #4ccfc4;
}
.asset-update-footer :last-child {
  margin-left: 20px;
  background-color: #fff;
}
.asset-update-footer :last-child:hover {
  color: #4ccfc4;
  border-color: #4ccfc4;
}
.asset-update-id-name {
  width: 259px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  border-radius: 5px;
  padding-left: 20px;
  box-sizing: border-box;
  background-color: #F6F6F6;
  border: 1px solid #D9D9D9;
  color: #B8B8B8;
  font-size: 14px;
}
.asset-update-span {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  font-family: Noto Sans SC;
}
.asset-update-img {
  width: 68px;
  height: 68px;
  display: inline-block;
  margin-left: 30px;
}
.asset-update-img img {
  width: 100%;
  height: 100%;
  vertical-align: text-top;
}
.amps_asset_water_fall_v2 {
  align-content: flex-start;
  background-color: #000;
  padding-top: 8px;
  height: 82.593vh;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.amps_asset_water_fall_v2_init_loading {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  color: #484848;
  text-align: center;
}
.amps_asset_water_fall_v2_footer {
  position: relative;
  height: 20px;
  width: 100%;
  flex: 0 0 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px;
}
.amps_asset_water_fall_v2_footer_text {
  color: #fff;
  font-size: 14px;
  text-align: center;
}
.board-border {
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 16px;
  height: 100%;
  padding: 20px;
  overflow: scroll;
  color: #000000;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}
.between {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.around {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}
.board-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}
.board-title:hover {
  color: #29c1b9;
}
.board-view-all {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.board-hover:hover {
  color: #008e87;
  cursor: pointer;
}
.board-number {
  color: #1a9da6;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
.pd-r-30 {
  padding-right: 30px;
}
.pd-b-24 {
  padding-bottom: 24px;
}
.pd-lr-30 {
  padding: 0px 30px;
}
.pd-tb-18 {
  padding: 18px 0px;
}
.tr {
  text-align: right;
}
.in-block {
  display: inline-block;
}
.float-r {
  float: right;
}
.w-176 {
  width: 176px;
}
.amp-flex-row-start {
  display: flex;
  justify-content: flex-start;
}
.width-274 {
  width: 274px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width-254 {
  display: inline-block;
  max-width: 350px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.matter-onlyHeader .ant-table-tbody {
  display: none;
}
.matter-onlyHeader .ant-table-thead th:first-child {
  padding-left: 50px;
}
.matter .ant-table-body {
  cursor: pointer;
}
.matter .ant-table-thead {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.matter .ant-table-tbody > tr {
  height: 80px;
  overflow: hidden;
}
.matter .ant-table-tbody > tr > td {
  padding-top: 6px;
  padding-bottom: 0;
}
.matter .ant-table-container table > thead > tr:first-child th:first-child {
  padding-left: 50px;
}
.matter .ant-table-row td:first-child {
  padding-left: 50px;
}
.ant-empty {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ta-r {
  text-align: right;
}
.cursor {
  cursor: pointer;
}
.details .bold {
  width: 56px;
  text-align: right;
}
.details .reset-filter {
  padding-left: 10px;
}
.details form div.site-input-group-wrapper.total {
  margin-right: 14px;
}
.history-item {
  display: flex;
  position: relative;
  line-height: 18px;
  margin-top: 4px;
  margin-bottom: 8px;
  font-size: 14px;
  height: fit-content;
}
.history-item-title {
  width: 4.0625vw;
}
.history-content {
  margin-top: 40px;
  margin-left: -26px;
}
.two-col-history {
  display: flex;
  width: 43.75vw;
  flex-flow: row wrap;
  position: relative;
}
.two-col-history > div {
  width: 50%;
}
.two-col-history .history-item-content {
  width: 17.708333333333336vw;
}
.one-col-history {
  display: flex;
  width: 43.75vw;
  flex-flow: row wrap;
  position: relative;
}
.one-col-history > div {
  width: 100%;
}
.one-col-history .history-item-content {
  width: 38.54166666666667vw;
}
.three-col-history {
  display: flex;
  width: 43.75vw;
  flex-flow: row wrap;
  position: relative;
}
.three-col-history > div {
  width: 33%;
}
.three-col-history .history-item-content {
  width: 10.3125vw;
}
.people-display {
  display: flex;
  margin-top: -12px;
  flex-flow: row wrap;
}
.people-display .ant-avatar {
  margin-bottom: 8px;
}
.history-headline {
  margin-top: 30px;
  margin-bottom: 16px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: auto;
  left: 350px;
}
.history-images {
  margin-left: -7px;
  display: flex;
  flex-flow: row wrap;
}
.history-images .ant-image {
  margin: 7px;
}
.history-images .ant-image .ant-image-img {
  object-fit: contain;
  background-color: black;
}
.history-timeline-title {
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 40px;
}
.history-person {
  margin-bottom: 30px;
}
.history-person .in-block {
  display: inline-block;
}
.history-person .history-person-left {
  font-family: Noto Sans SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}
.history-person .history-person-right {
  width: 28px;
  height: 28px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 28px;
  background: #C9CCCF;
  color: #2B364F;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
  text-align: center;
}
.collapse-header {
  transition: all 0.3s, visibility 0s;
  transition-property: all, visibility;
  transition-duration: 0.3s, 0s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
}
.collapse-arrow svg {
  transition: transform 0.24s;
  transition-property: transform;
  transition-duration: 0.24s;
  transition-timing-function: ease;
  transition-delay: 0s;
}
.collapse-content {
  transition: all 0.3s;
  transition-timing-function: ease;
  margin-top: 4px;
}
.collapse-content .ant-timeline-item {
  padding-bottom: 0 !important;
}
.collapse-content .ant-timeline-item .ant-timeline-item-tail {
  height: calc(100% - 10px);
}
.history-item-image {
  width: 38.54166666666667vw;
  display: flex;
  flex-flow: row wrap;
  height: fit-content;
  margin-top: 4px;
}
.history-item-col-2 {
  width: 50%;
}
.history-item-col-2 .history-item-content {
  width: 17.708333333333336vw;
}
.history-item-col-1 {
  width: 100%;
}
.history-item-col-1 .history-item-content {
  width: 38.54166666666667vw;
}
.amp-history-item-col-1 {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.amp-history-item-col-1 .history-item-content {
  width: 17.708333333333336vw;
}
.history-record-area {
  width: 43.75vw;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin-top: 8px;
}
.history-step-basic,
.current-history-step,
.past-history-step,
.future-history-step {
  width: 24px;
  height: 22px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px;
}
.current-history-step {
  background: #29C1B9;
  color: #ffffff;
}
.past-history-step {
  background: #f6f6f6;
  color: #000000;
}
.future-history-step {
  background: #f6f6f6;
  color: #d7d7d7;
}
.account-profile {
  box-sizing: border-box;
  padding: 50px 40px 0px 40px;
}
.profile-info-section {
  display: flex;
  flex-flow: row wrap;
  width: 57.291666666666664vw;
  justify-content: space-between;
  margin-top: 25px;
}
.validation-code {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.styles_react-code-input__CRulA input {
  font-family: NotoSans !important;
  border: none !important;
  font-size: 32px !important;
  text-transform: uppercase !important;
}
.styles_react-code-input__CRulA input:last-child {
  border-right: none !important;
}
.styles_react-code-input__CRulA input:focus {
  border: none !important;
  caret-color: black !important;
}
.dashed-line {
  margin-left: 3px;
  width: 446px;
  height: 1px;
  background-image: linear-gradient(to right, #9b9b9b 0%, #9b9b9b 88%, transparent 50%);
  background-size: 74.3px 18px;
  background-repeat: repeat-x;
}
.ant-carousel .slick-dots {
  display: none !important;
}
.projects-head {
  display: flex;
  justify-content: space-between;
}
.table-head {
  font-weight: 700;
}
.ant-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.produce-score {
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #888888;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 8px;
}
.produce-score-color {
  background-color: #29C1B9;
}
.produceTaskPondModel {
  overflow: hidden;
}
.produceTaskPondModel .ant-modal-content .ant-modal-body {
  padding: 16px 0 15px 0 !important;
  height: 820px;
  overflow: hidden !important;
}
.pond-title {
  font-size: 18px;
  font-weight: 400;
  height: 51px;
  line-height: 36px;
  padding-left: 32px;
  border: 0px solid #E4E7EF;
  border-bottom-width: 1px;
  font-family: Noto Sans SC;
}
.pond-title span {
  cursor: pointer;
}
.produce-take-count:hover {
  color: #29C1B9;
  cursor: pointer;
}
.pond-reset {
  color: #191919;
  font-weight: bolder;
  font-size: 15px;
  cursor: pointer;
}
.pond-description {
  padding: 0 32px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}
.pond-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  padding-left: 32px;
  padding-top: 20px;
}
.IconHeight {
  width: 43px;
  height: 19px;
  display: inline-block;
}
.IconHeight span {
  width: 100%;
  height: 100%;
}
.IconHeight span svg {
  width: 100%;
  height: 100%;
}
.pond-middle {
  display: flex;
  flex-direction: column;
  height: 615px;
}
.pond-middle .pond-middle-header {
  padding: 0 32px;
}
.pond-middle .pond-middle-header .pond-filtrate-by-type {
  margin-top: 16px;
}
.pond-middle .pond-middle-header .pond-filtrate-by-type .pond-filtrate {
  border: 1px solid #E4E7EF;
  font-size: 14px;
  font-weight: 300;
  padding: 0px 12px;
  margin-bottom: 16px;
  height: 26px;
  line-height: 26px;
  border-radius: 20px;
  display: inline-block;
  font-family: Noto Sans SC;
}
.pond-middle .pond-content {
  flex: 1;
  padding: 0 24px;
  padding-right: 18px;
  overflow: scroll;
}
.pond-middle .pond-content .loadingSpin {
  height: 40px;
  width: 100%;
  text-align: center;
  bottom: 10px;
  position: relative;
  font-size: 14px;
}
.pond-middle .pond-content .pond-empty {
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-top: -54px;
  position: relative;
  text-align: center;
}
.pond-middle .pond-content .pond-empty p {
  color: #818181;
  font-size: 14px;
  font-weight: 300;
}
.pond-middle .pond-content .single-task {
  box-sizing: border-box;
  width: 15.625vw;
  display: inline-block;
  margin-bottom: 16px;
  height: 172px;
  padding: 14px;
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
}
.pond-middle .pond-content .single-task-active {
  border: 1px solid #29C1B9 !important;
}
.pond-middle .pond-content .single-task-operate {
  cursor: pointer;
}
.pond-middle .pond-content .single-task .header {
  height: 30px;
  margin-bottom: 0;
  position: relative;
}
.pond-middle .pond-content .single-task .header .header-icon {
  width: 20px;
  display: inline-block;
}
.pond-middle .pond-content .single-task .header .checked-icon {
  float: right;
  display: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
.pond-middle .pond-content .single-task .header .checked-icon span {
  height: 100%;
  width: 100%;
  display: inline-block;
}
.pond-middle .pond-content .single-task .header .checked-icon span svg {
  height: 100%;
  width: 100%;
}
.pond-middle .pond-content .single-task .header .split-icon {
  cursor: pointer;
  height: 18px;
  width: 18px;
  position: absolute;
  margin-left: 6px;
}
.pond-middle .pond-content .single-task .header .split-icon span {
  height: 100%;
  width: 100%;
  display: inline-block;
}
.pond-middle .pond-content .single-task .header .split-icon span svg {
  height: 100%;
  width: 100%;
}
.pond-middle .pond-content .single-task .details {
  height: 20px;
  line-height: 20px;
  width: 100%;
  overflow: hidden;
  display: flex;
}
.pond-middle .pond-content .single-task .details span {
  display: inline-block;
  vertical-align: top;
}
.pond-middle .pond-content .single-task .details .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.pond-middle .pond-content .single-task .potos {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow: hidden;
  height: 88px;
}
.pond-middle .pond-content .single-task .potos .single-photo {
  display: inline-block;
  margin-right: 10px;
}
.pond-middle .pond-content .single-task .potos .single-photo .model-image {
  -webkit-filter: opacity(20%);
  filter: opacity(20%);
}
.pond-middle .pond-content .single-task:hover {
  border-color: #29C1B9;
}
.pond-middle .pond-content::-webkit-scrollbar {
  display: block;
  width: 6px;
}
.pond-middle .pond-content::-webkit-scrollbar-track {
  border: 4px solid transparent;
  background-clip: content-box;
}
.pond-middle .pond-content::-webkit-scrollbar-button {
  height: 0 !important;
  width: 0 !important;
}
.pond-middle .pond-content::-webkit-scrollbar-thumb {
  background: #c8c8c8 !important;
  border-radius: 40px;
  width: 50%;
  border: 3px solid transparent;
}
.pond-middle .pond-content::-webkit-scrollbar-thumb:horizontal:hover {
  background: #b6b6b6 !important;
}
.bg-blue {
  background-color: #29C1B9;
  color: #fff;
}
.bg-white {
  background-color: #fff;
  color: #000;
}
.bg-gray {
  color: #E0E0E0;
}
.made-scroll .ant-input-number-input-wrap {
  height: 100% !important;
}
.made-scroll .ant-input-number-input-wrap input {
  height: 100% !important;
}
.font-color {
  color: #0e1114;
  font-weight: 300;
  font-size: 14px;
}
.amp-origin-border {
  border-bottom: 1px solid #E0E0E0;
}
.settings-role-head {
  display: flex;
  justify-content: space-between;
}
.role-permissions .ant-tree-list-holder-inner {
  background-color: #F5F5F5;
  padding: 21px 40px;
  height: 372px;
  overflow-y: scroll;
}
.ant-tree-node-selected {
  background-color: transparent !important;
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-slide .ant-image-error img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: none;
}
.swiper-button-next,
.swiper-button-prev {
  width: 64px !important;
  height: 64px !important;
  background-color: #F5F5F5;
  border-radius: 50%;
  color: #BFBFBF !important;
  opacity: 0.35;
  margin-top: -32px !important;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: 0.5;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
.login-wrapper {
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  padding: 24px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 38.75vw;
  height: 52.083333333333336vw;
  left: 61.25000000000001vw;
}
.powered-by-ecoplants {
  width: 11.458333333333332vw;
  position: absolute;
  right: 26px;
  top: 24px;
  z-index: 2000;
}
.login-form {
  margin-top: 60px;
}
.session-bg {
  width: 61.25000000000001vw;
  height: 100vh;
  box-sizing: border-box;
}
.session-bg img {
  object-fit: fill;
}
.login-title {
  display: flex;
  width: 11.458333333333332vw;
  height: 4.166666666666666vw;
  position: relative;
  align-items: center;
  box-sizing: border-box;
}
.login-title img {
  width: 100%;
}
.session-error {
  min-height: 1.3020833333333335vw;
  width: 19.791666666666664vw;
  color: #ec3b3b;
}
.session-button {
  width: 19.791666666666664vw !important;
  height: 2.2916666666666665vw !important;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
}
.session-button:hover {
  border: none;
  background-color: #7ac3cb;
}
.settings {
  background: #fff !important;
  height: 100vh;
}
.settings .ant-menu-inline {
  height: 100%;
}
.settings .ant-menu-item {
  text-align: center !important;
}
.settings-user-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-user-btn {
  position: absolute;
  right: 0px;
  top: 0;
}
.ant-layout-sider {
  background: #fff !important;
}
.edit-user-select .ant-select-selector {
  height: 40px !important;
  border-radius: 2px;
}
.edit-user-select .ant-select-selection-item,
.edit-user-select .ant-select-selection-placeholder {
  line-height: 40px !important;
}
.cursor-default {
  cursor: default !important;
}
.user-form {
  position: relative;
}
.user-form .user-item-input {
  width: 260px;
  display: inline-block;
}
.user-form .user-item-input .normal-input {
  height: 40px;
}
.user-form .user-item-input .ant-input-disabled {
  background: #fff !important;
  cursor: pointer;
  color: #000 !important;
}
.user-form .edit-user-text-btn {
  position: absolute;
  width: 28px;
  height: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 0px !important;
  top: 48px;
  left: 272px;
  color: #66B2BB;
}
.settings-user-content {
  padding-top: 20px ;
}
.reset-filter {
  display: inline-block;
  font-weight: 700;
  height: 40px;
  line-height: 2.083333333333333vw;
  cursor: pointer;
  padding-left: 20px;
}
.dialog-del-footer {
  margin-top: 68px;
}
.user-dialog-footer {
  bottom: 48px;
  left: 0px;
  padding-left: 60px;
  position: absolute;
}
.editUser .ant-modal-body,
.editRole .ant-modal-body {
  height: 758px;
}
.tox-statusbar {
  display: none !important;
}
.task-card {
  min-width: 180px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  height: fit-content;
  cursor: pointer;
  padding: 14px 0;
}
.task-card-content {
  padding: 0 12px;
  box-sizing: border-box;
}
.task-list {
  min-width: 320px;
  background: #f4f5f7;
  width: 15.312500000000002vw;
  border-radius: 10px;
  margin-right: 20px;
  padding: 0px 10px 0px 10px;
}
.task-list-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 73.14814814814815vh;
}
.task-list-title {
  background: #f4f5f7;
  height: 3.888888888888889vh;
  padding: 16px 6px 8px 6px;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 1000;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.task-card-head {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
}
.task-card-head .iconAB {
  width: 28px;
  height: 14px;
  display: inline-block;
}
.task-card-head .iconAB span {
  width: 100%;
  height: 100%;
}
.task-card-head .iconAB span svg {
  width: 100%;
  height: 100%;
}
.task-card-head .amp-task-card-bd {
  height: 20px;
  line-height: 20px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  padding: 0 7px;
  font-size: 12px;
  color: #595959;
}
.toggleIconAB {
  cursor: pointer;
  width: 43px;
  height: 22px;
  display: inline-block;
}
.toggleIconAB span {
  width: 100%;
  height: 100%;
}
.toggleIconAB span svg {
  width: 100%;
  height: 100%;
}
.task-card-people {
  display: flex;
  flex-flow: row wrap;
}
.task-card-people-section {
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-top: 8px;
}
.task-card-img {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  width: fit-content;
}
.task-card-img img {
  object-fit: scale-down;
  background-color: black;
  border-radius: 2px;
}
.task-card-title {
  font-weight: 700;
  font-size: 16px;
  margin-top: 8px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.task-card-comment {
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.spin-height {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spin-large {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.task-card-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.task-status-display {
  height: 22px;
  font-size: 13px;
  line-height: 22px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 3px 6px;
  box-sizing: border-box;
}
.task-board {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  box-sizing: border-box;
}
.task-board::-webkit-scrollbar {
  display: block;
  width: 4px;
  /* 纵向滚动条*/
  height: 8px;
  /* 横向滚动条 */
}
.task-board::-webkit-scrollbar-track {
  border: 4px solid transparent;
  background-clip: content-box;
  /* THIS IS IMPORTANT */
}
.task-board::-webkit-scrollbar-button {
  height: 0 !important;
  width: 0 !important;
}
.task-board::-webkit-scrollbar-thumb {
  background: #c8c8c8 !important;
  border-radius: 40px;
  width: 50%;
  border: 3px solid transparent;
}
.task-board::-webkit-scrollbar-thumb:horizontal:hover {
  background: #b6b6b6 !important;
}
.task-board > :last-child {
  margin-right: 0 !important;
}
.amp-drawer-title-fixed {
  position: fixed;
  width: 100%;
  background: #FFF;
  z-index: 999;
  padding-top: 70px;
  margin-top: -78px;
}
.dashed-square-display {
  min-width: 148px;
  min-height: 90px;
  border: 1px dashed #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.dashed-square-display:last-of-type {
  margin-right: 0;
}
.square-display-data {
  display: flex;
  justify-content: center;
  padding-top: 0.4166666666666667vw;
  padding-bottom: 0.6770833333333334vw;
  line-height: 20px;
  font-size: 32px;
}
.single-rank-item {
  width: 28.385416666666668vw;
  height: 2.9166666666666665vw;
  background: #f5f5f6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  color: black;
  margin-top: 8px;
  margin-bottom: 8px;
}
.single-rank-item:first-child {
  margin-top: 0px;
}
.rank-normal,
.rank-top {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rank-top {
  background: #e9daff;
  border-radius: 4px;
  color: #8b3eff;
}
.rank-table {
  width: 31.25vw;
  padding: 20px 30px;
  box-sizing: border-box;
  background: #fbfbfc;
  overflow-y: scroll;
}
.rank-table-scroll {
  width: 28.645833333333332vw;
  overflow: auto;
}
.bar-box {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}
.bar-box .bar {
  flex: 1;
  padding-top: 20px;
  position: relative;
  height: 100%;
  background: #fbfbfc;
}
.rank-table-labels {
  width: 28.385416666666668vw;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 24px;
  box-sizing: border-box;
}
.rank-item-base,
.rank-sort,
.rank-name,
.rank-score,
.rank-task {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rank-sort {
  width: 2.87vw;
}
.rank-name {
  width: 6.875vw;
}
.rank-score {
  width: 7.6vw;
}
.rank-task {
  width: 10.8vw;
}
.summary-table {
  background: #fbfbfc;
  border-radius: 4px;
  padding: 14px 32px 14px 30px;
  box-sizing: border-box;
}
.show-detail {
  color: #00ada4;
  background: #d4f3f1;
  width: 64px;
  height: 24px;
  border-radius: 30px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.show-detail:hover {
  background: #53cdc6;
  color: #ffffff;
}
.grouped-assets {
  width: 34.375vw;
}
.ungrouped-assets {
  width: 13.541666666666666vw;
}
.groups {
  margin-right: -8px;
  padding-right: 8px;
}
.groups ::-webkit-scrollbar-button {
  height: 40px !important;
}
.ungrouped-droppable {
  width: 13.541666666666666vw !important;
  display: flex;
  flex-flow: row wrap;
  margin-right: -0.5208333333333333vw;
  min-height: 200px;
}
.group-drawer .ant-drawer-body,
.group-drawer .ant-drawer-content {
  overflow: hidden !important;
}
.ungrouped-filter {
  width: 12.916666666666668vw !important;
  box-sizing: border-box;
}
.DraggableTags-tag {
  cursor: grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;
  z-index: 30 !important;
}
.DraggableTags-tag:active {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}
.DraggableTags-tag-drag {
  z-index: 100 !important;
}
.single-drop-area {
  min-height: 13.333333333333334vh;
  width: 34.375vw;
  border: 1px dashed #e0e0e0;
  margin-top: 12px;
  padding: 8px;
  box-sizing: border-box;
  position: relative;
}
.single-group-name .ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0px !important;
}
.drag-drop-area {
  display: flex;
  justify-content: space-between;
  margin-right: -40px;
  padding-right: 40px;
  overflow: auto;
  height: 58.333333333333336vh;
}
.draggable-img-wrapper {
  background: black;
  width: 6.25vw;
  height: 6.25vw;
  margin: 2px 4px 2px 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.draggable-img-wrapper:hover {
  border: 2px solid #29c1b9;
}
.draggable-img-wrapper img {
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  transition: opacity 500ms ease 0s,
        visibility 800ms ease 0s;
}
.draggable-img-wrapper img:hover {
  opacity: 0.5;
}
.drag-placeholder {
  display: flex;
  height: 13.333333333333334vh;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
}
.drag-placeholder img {
  width: 3.6979166666666665vw;
  height: 3.6458333333333335vw;
}
.drag-placeholder-none {
  display: none;
}
.project-title {
  min-height: 54px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
  cursor: default;
  justify-content: space-between;
}
.project-title-left {
  display: flex;
  line-height: 24px;
  box-sizing: border-box;
}
.project-title-right {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.project-sub-title {
  display: flex;
  height: 70px;
  line-height: 70px;
  justify-content: space-between;
  box-sizing: border-box;
}
.project-title-filter {
  border: 1px solid red;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  float: left;
  flex-wrap: wrap;
  height: 70px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  overflow: hidden;
  z-index: 50;
  position: relative;
  transition: height 0.3s, width 10s;
}
.project-title-filter .filter-box {
  width: 65.2vw;
}
.project-title-filter .operator-box {
  display: flex;
  align-items: center;
  position: relative;
  top: -35px;
  height: 70px;
}
.project-title-filter .operator-box .toggle-box {
  float: left;
  width: 40px;
  height: 40px;
  border: 1px solid #D9D9D9;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
}
.project-title-filter .operator-box .toggle-box span {
  top: -50%;
  transform: translateY(10px);
  position: relative;
}
.project-title-filter .operator-box .toggle-box span svg {
  width: 24px;
  height: 24px;
}
.project-title-filter .operator-box .toggle-box:hover {
  border-color: #4487DC;
}
.project-title-filter .operator-box .toggle-box:hover svg {
  color: #4487DC;
}
.selectEmpty .ant-empty-normal {
  margin: 8px !important;
}
.project-sub-right {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  float: right;
}
.project-sub-right .ant-btn {
  padding: 0px;
}
.asset-add-title-drawer {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 43px;
}
.asset-drawer-select {
  width: 210px;
  font-weight: 500;
}
.asset-drawer-select .ant-select-selector {
  height: 40px !important;
  border-radius: 2px;
}
.asset-drawer-select .ant-select-selection-item,
.asset-drawer-select .ant-select-selection-placeholder {
  line-height: 40px !important;
}
.asset-drawer-text .ant-form-item-label > label {
  font-size: 14px !important;
  font-weight: 500;
}
.asset-make-footer {
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
}
.asset-drawer-footer,
.asset-make-footer,
.asset-discard-footer {
  height: 80px;
  line-height: 80px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding-left: 60px;
  width: 100%;
  z-index: 400;
  background-color: white;
}
.asset-drawer-footer .ant-form-item,
.asset-make-footer .ant-form-item,
.asset-discard-footer .ant-form-item {
  margin-bottom: 0px;
  height: 80px;
  line-height: 80px;
}
.showCount .ant-form-item-control-input-content {
  height: 80px;
}
.asset-history-drawer .title-pos {
  position: relative !important;
}
.asset-history-drawer .title {
  position: fixed;
  justify-content: space-between;
}
.asset-history-drawer .title .right {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #4C4C4C;
}
.history-drawer-action-detail .ant-row {
  margin-bottom: 17px;
}
.history-drawer-action-detail .label {
  display: inline-block;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  width: 56px;
}
.history-drawer-action-detail .value {
  display: inline-block;
  color: #000000;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-left: 22px;
}
.history-timeline-IconHeader {
  margin: -35px 0px 11px 0px;
}
.drawer-input {
  width: 210px;
}
.drawer-input .normal-input {
  height: 40px;
}
.drawer-input-disabled {
  width: 210px;
  font-weight: 500;
}
.drawer-input-disabled .ant-row {
  display: none;
}
.drawer-input-disabled .normal-input {
  height: 40px;
}
.drawer-input-disabled .ant-input-disabled {
  background: #fff;
  cursor: pointer;
  color: #000 !important;
}
.to-void-drawer .ant-drawer-bottom {
  z-index: 500 !important;
}
.to-void-drawer .title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.to-void-drawer .tips {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #9B9B9B;
  margin-left: 8px;
}
.task-detail-people-section {
  display: flex;
  align-items: center;
  margin-right: 32px;
}
.createdForm .ant-form-item-label > label {
  font-size: 18px !important;
  font-weight: 500 !important;
}
.createdForm .idContent {
  font-weight: normal;
  font-size: 12px;
  color: #6D6D6D !important;
}
.bottom-drawer-disappear .ant-drawer-bottom {
  z-index: 0 !important;
}
img {
  object-fit: scale-down;
}
.amps-filter-section {
  height: 70px;
  position: relative;
  flex: 1;
}
.amps-filter-section-container-open {
  height: 140px;
  box-shadow: 0px 10px 12px rgba(124, 124, 124, 0.3);
}
.amps-filter-section-container-close {
  height: 70px;
}
.amps-filter-section-container {
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  transition: height 0.5s ease;
  background-color: #fff;
  width: calc(100% + 100px);
  margin: 0 -50px;
  position: absolute;
  padding: 0 50px;
}
.amps-filter-section-container-form {
  flex: 1;
}
.amps-filter-section-container-form-item {
  width: 100%;
}
.amps-filter-section-container-buttons {
  flex: 0 0 120px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amps-filter-section-container-buttons-switch {
  margin-top: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.amps-filter-section-container-buttons-default-switch {
  border: 1px solid #d9d9d9;
}
.amps-filter-section-container-buttons-open-switch {
  border: 1px solid #4487DC;
}
.amps-filter-section-container-buttons-btn {
  margin-top: 16px;
  height: 40px;
}
.profile-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -14px !important;
  cursor: default;
}
.ant-avatar {
  cursor: default;
}
.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.ant-popover-inner-content {
  display: flex;
  max-width: 350px;
  text-align: left;
  flex-flow: row wrap;
}
.ant-popover-inner-content .ant-avatar {
  margin: 5px;
}
.amp-header-storages-popover .ant-popover-title {
  border: none;
  padding: 0 16px;
}
.amp-header-storages-popover .amp-header-storages-popover-title {
  display: flex;
  min-width: 320px;
  justify-content: space-between;
  align-items: center;
  line-height: 40px;
  padding: 5px 0;
  border-bottom: 1px dashed #E5E5E5;
}
.amp-header-storages-popover .amp-header-storages-popover-maintext {
  font-size: 24px;
  color: #29c1b9;
  margin-right: 10px;
}
.amp-header-storages-popover .amp-header-storages-popover-close {
  height: 40px;
  width: 40px;
  text-align: center;
  cursor: pointer;
}
.amp-header-storages-popover .amp-header-storages-popover-content {
  max-height: 360px;
  overflow: auto;
  font-size: 14px;
  flex: 1;
}
.amp-header-storages-popover .amp-header-storages-popover-line {
  display: flex;
  align-items: center;
  min-width: 0px;
  line-height: 36px;
  color: #666;
}
.amp-header-storages-popover .amp-header-storages-popover-line:hover {
  background-color: #F3FFFD;
}
.amp-header-storages-popover .amp-header-storages-popover-taskid {
  min-width: 110px;
  margin-right: 15px;
}
.amp-header-storages-popover .amp-header-storages-popover-taskname {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.btn-sz-m {
  width: 120px;
  height: 40px;
}
.btn-sz-s {
  width: 100px;
  height: 40px;
}
.btn-noBorderWidth {
  border-width: 0px !important;
  background-color: #E8E8E8 !important;
}
.btn-abled {
  background-color: #29C1B9 !important;
  color: #fff !important;
}
.ant-btn {
  border-radius: 4px !important;
}
.span-btn {
  width: 100%;
  height: 40px;
}
.ant-btn-icon-only {
  width: 40px;
  height: 40px;
}
.dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #b6b6b6 !important;
}
.dropdown-btn:hover,
.dropdown-btn:active,
.dropdown-btn:focus {
  background-color: white !important;
  border-color: #b6b6b6 !important;
}
.blue-btn {
  color: #29c1b9 !important;
  border-color: #29c1b9 !important;
}
.amp-button-primary {
  min-width: 80px;
  max-width: 100px;
  height: 40px;
}
.amp-button-default {
  width: 80px;
  min-width: 80px;
  max-width: 100px;
  height: 40px;
}
.ant-modal-content {
  border-radius: 6px !important;
}
.large-form-dialog .ant-modal-body {
  padding: 36px 52px 48px 52px;
  position: relative;
}
.large-form-dialog .ant-form-item {
  margin-bottom: 30px;
}
.projectModel .ant-modal-content .ant-modal-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow: scroll !important;
}
.projectModel .ant-modal-content .ant-modal-body .dialog-title {
  padding-left: 52px;
}
.projectModel .ant-modal-content .ant-modal-body .dialog-footer {
  padding-left: 52px;
}
.projectModel .dialog-content-middle {
  width: 100%;
  padding: 0px 52px;
  height: 630px;
  overflow: scroll;
}
.projectModel .dialog-content-middle::-webkit-scrollbar {
  display: block;
  width: 8px;
  /* 纵向滚动条*/
}
.projectModel .dialog-content-middle::-webkit-scrollbar-track {
  border: 4px solid transparent;
  background-clip: content-box;
  /* THIS IS IMPORTANT */
}
.projectModel .dialog-content-middle::-webkit-scrollbar-button {
  height: 0 !important;
  width: 0 !important;
}
.projectModel .dialog-content-middle::-webkit-scrollbar-thumb {
  background: #c8c8c8 !important;
  border-radius: 40px;
  width: 50%;
  border: 3px solid transparent;
}
.projectModel .dialog-content-middle::-webkit-scrollbar-thumb:horizontal:hover {
  background: #b6b6b6 !important;
}
.projectModel .dialog-content-middle .dialog-content-middle-box .input-sz-m {
  width: 100% !important;
}
.projectModel .dialog-footer {
  margin-top: 0 !important;
}
.confidential {
  margin-bottom: 16px !important;
}
.confidential-users-list div {
  display: inline-block;
  position: relative;
  width: 56px;
  height: 56px;
}
.confidential-users-list div span {
  z-index: 20;
  top: 0;
  right: 0;
  position: absolute;
}
.confidential-users-list div .close-icon {
  display: none;
  position: absolute;
  top: 0;
  right: -20px;
}
.confidential-users-list div .ant-avatar-string {
  font-size: 14px;
}
.confidential-users-list .profile-box:hover .close-icon {
  display: block;
}
.confidential-users-list .profile-box span {
  width: 48px;
  height: 48px;
  font-size: 16px;
}
.medium-form-dialog .ant-modal-body {
  padding: 60px 52px 40px 52px;
  position: relative;
}
.dialog-prompt-title {
  font-size: 18px;
  line-height: 24px;
}
.dialog-prompt-title span {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  padding-left: 10px;
}
.dialog-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 43px;
}
.dialog-footer-shadow {
  padding-top: 16px !important;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.04);
}
.dialog-title-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}
.mg-review {
  margin-bottom: 0 !important;
  padding-bottom: 24px !important;
}
.dialog-heading {
  margin-bottom: 42px;
}
.skip-text {
  text-decoration: underline;
  cursor: pointer;
}
.dialog-footer {
  margin-top: 26px;
}
.dialog-footer .ant-form-item {
  margin-bottom: 0px;
}
.image-preview-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  top: 0;
  height: 100%;
}
.image-preview-modal .ant-modal-content {
  background-color: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.image-preview-modal .ant-modal-body {
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow: transparent !important;
}
.image-preview-modal .ant-modal-close-x {
  display: none !important;
}
.preview-img {
  max-width: 82vw;
  object-fit: scale-down;
}
.ant-modal-wrap {
  overflow: hidden !important;
}
.ant-modal-close-x {
  font-size: 22px !important;
}
.ant-modal-body {
  overflow: auto;
}
.ant-modal-body::-webkit-scrollbar-thumb {
  background-color: transparent;
  box-shadow: none;
}
.dialog-pd {
  padding: 20px 28px 24px 28px!important;
}
.waterfall-dialog .ant-modal-body {
  max-height: 100vh !important;
  overflow: hidden;
}
.waterfall-dialog .ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}
.waterfall-preview-image {
  background-color: #000;
  position: absolute;
  overflow: scroll;
  top: 4.270833333333333vw;
  left: 2.083333333333333vw;
  right: 2.083333333333333vw;
  width: 95.834vw;
  height: 84.815vh;
  padding-top: 8px;
}
.waterfall-preview-image .waterfall-preview-box {
  height: 15.625vw;
  margin-bottom: 0.416vw;
}
.waterfall-preview-image .waterfall-preview-box .render-item {
  height: 15.625vw;
  overflow: hidden;
  text-align: center;
  background-color: #0F0F0F;
  display: inline-block;
  margin-right: 0.416vw;
}
.waterfall-preview-image .waterfall-preview-box .render-item .ant-image {
  width: 100%;
  height: 100%;
}
.waterfall-preview-image .waterfall-preview-box .render-item img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.waterfall-preview-image .waterfall-preview-box :last-child {
  margin-right: 0;
}
::-webkit-scrollbar {
  display: none;
}
.ant-drawer-body {
  padding: 40px 40px 150px 48px;
  position: absolute;
  top: 7.4074074074074066vh;
  width: 100%;
}
.drawer-close-icon-active {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #ffffff;
  box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  font-size: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  z-index: 301 !important;
  top: 78px;
  transition: right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-drawer {
  z-index: 300 !important;
}
.drawer-close-icon-inactive {
  opacity: 0;
  position: absolute;
  top: 10px;
  transition: right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 20px 60px 20px 50px;
  background-color: white;
  height: 80px;
  box-sizing: border-box;
  border-top: none !important;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
  z-index: 50;
}
.ant-drawer-bottom .ant-drawer-body {
  padding: 30px 20px 20px 60px;
  position: absolute;
  top: inherit;
  width: 100%;
  height: 100%;
}
.second-drawers-form {
  margin-bottom: 0px !important;
}
.ant-form-item .ant-input-textarea-show-count::after {
  right: 10px;
}
.bottom-drawer-visible .ant-drawer-bottom {
  z-index: 500 !important;
}
.bottom-drawer-disappear .ant-drawer-bottom {
  z-index: 0 !important;
}
.ant-drawer-footer {
  z-index: 50 !important;
}
.amp-in-drawer {
  padding-bottom: 60px;
}
.amp-in-drawer .amp-in-drawer-button {
  width: 100%;
  height: 80px;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
  background: #FFF;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px 60px;
}
.amp-drawer-score {
  display: flex;
  align-items: flex-start;
}
.amp-drawer-score .amp-drawer-score-stepper {
  display: flex;
  justify-content: space-between;
  width: 10.9375vw;
}
.amp-drawer-score .amp-score-input {
  width: 5.208333333333334vw;
  text-align: center;
}
.amp-drawer-score .amp-score-button {
  color: #C4C4C4;
  border-color: #D9D9D9;
}
.amp-drawer-score .amp-score-button:hover {
  color: #4D4D4D;
}
.amp-drawer-score .amp-score-button:disabled {
  color: #C4C4C4;
}
.amp-drawer-score .amp-drawer-score-label {
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
}
.amp-drawer-score .amp-drawer-score-label-item {
  height: 40px;
  line-height: 40px;
  width: 40px;
  background-color: #F4F5F7;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  margin: 0 8px 8px 0;
}
.amp-drawer-score .amp-drawer-score-label-item:hover,
.amp-drawer-score .amp-drawer-score-label-item.current {
  background-color: #F3FFFD;
}
.amp-drawer-opinion .amp-drawer-opinion-quick {
  display: flex;
  align-items: flex-start;
}
.amp-drawer-opinion .amp-drawer-opinion-label {
  flex: 1;
  display: flex;
  min-width: 0px;
}
.amp-drawer-opinion .amp-drawer-opinion-taglist {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  min-width: 0px;
}
.amp-drawer-opinion .amp-drawer-opinion-key {
  margin: 0 10px 10px 0;
  max-width: 31%;
  min-height: 24px;
  line-height: 24px;
  background-color: #F4F5F7;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.amp-drawer-opinion .amp-drawer-opinion-key:hover {
  background-color: #E7FFFB;
}
.amp-drawer-opinion .amp-drawer-opinion-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.amp-drawer-opinion .amp-drawer-opinion-delete {
  margin-left: 5px;
}
.amp-drawer-opinion .amp-drawer-opinion-delete .icon-close {
  padding-left: 5px;
  border-left: 1px solid #E5E5E5;
}
.amp-drawer-opinion .amp-drawer-opinion-button {
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.amp-drawer-opinion .amp-opinion-button-icon {
  min-width: 50px;
}
.amp-drawer-opinion .amp-opinion-button {
  color: #E5E5E5;
}
.amp-drawer-opinion .amp-opinion-button:hover {
  color: #4D4D4D;
}
.amp-drawer-opinion .amp-drawer-opinion-add {
  min-width: 120px;
  justify-content: center;
}
.amp-drawer-opinion .amp-drawer-opinion-input {
  border: none;
  line-height: 24px;
  background: none;
  box-shadow: none;
  min-height: 24px;
  flex: 1;
  padding: 0;
}
.amp-drawer-opinion .amp-drawer-opinion-input:focus {
  border: none;
}
.amp-split-tasks {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-flow: column wrap;
  padding: 15px 20px;
}
.amp-split-tasks .amp-split-task {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
}
.amp-split-tasks .amp-split-task .ant-divider-horizontal {
  margin-top: 0;
}
.amp-split-tasks .amp-split-task-item {
  width: 50%;
}
.amp-split-tasks .amp-split-task-item .ant-form-item {
  height: 40px;
}
.amp-split-tasks .amp-split-task-item .ant-form-item .input-sz-m {
  width: 90% !important;
}
.amp-split-tasks .amp-split-task-item .ant-form-item .input-sz-m .ant-select {
  width: 100% !important;
}
.amp-split-task-w-100 {
  width: 100%;
}
.amp-split-task-w-100 .ant-form-item-label {
  font-weight: 500;
}
.amp-split-task-w-100 .ant-form-item-label > label::after {
  content: "";
}
.amp-refresh-tip {
  display: inline-block;
  padding: 0px 10px;
  margin-left: 10px;
  background-color: #FFF7E6;
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  font-weight: 300;
}
.amp-process-asset-size {
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding: 20px 20px 0;
  margin-bottom: 20px;
}
.amp-process-asset-size .amp-process-asset-label {
  height: 40px;
  line-height: 40px;
}
.amps_fall_item {
  overflow: hidden;
  display: flex;
  padding: 3px;
  align-items: stretch;
  height: 15VW;
  position: relative;
}
.amps_fall_item_box {
  flex: 1;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #0f0f0f;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.amps_fall_item_box_img_box {
  width: 100%;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  display: flex;
  position: relative;
}
.amps_fall_item_box_img_box .ant-image {
  height: 100%;
  width: 100%;
}
.amps_fall_item_box_img_box .ant-image img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.amps_fall_item_box_text {
  position: absolute;
  display: none;
  opacity: 0;
  transition: all 0.2s;
  bottom: 5px;
  align-items: center;
  width: 100%;
}
.amps_fall_item_box_text p {
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #484848;
  font-size: 12px;
  flex: 1;
  padding: 0 15px;
}
.amps_fall_item_box_text p:last-child {
  width: 110px;
  text-align: right;
  float: right;
}
.amps_fall_item_box_text p:hover {
  color: #29C1B9;
  cursor: pointer;
}
.amps_fall_item_box:hover {
  border: 1px solid #3C3C3C;
}
.amps_fall_item_box:hover .amps_fall_item_box_text {
  opacity: 1;
  display: flex;
}
.amps_fall_item .amp-split-icon {
  font-size: 20px;
  position: absolute;
  top: 15px;
  right: 12px;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: pointer !important;
}
.project-filters {
  display: flex;
  font-size: 14px !important;
  width: 100%;
  flex-flow: row wrap;
  position: relative;
}
.ant-select-selector {
  height: 40px;
  line-height: 35px;
}
.reset-filter {
  font-weight: 700;
  height: 40px;
  cursor: pointer;
}
.waterfall-button {
  position: absolute;
  right: 0;
}
.role-filter {
  display: flex;
  font-size: 14px !important;
}
.ant-select-multiple .ant-select-selection-item {
  background: transparent !important;
  border: none !important;
}
.filter-input,
.filter-input-focused {
  width: 11.979166666666668vw !important;
  height: 40px !important;
}
.filter-input .ant-picker-separator {
  display: none;
}
.filter-input .ant-picker-input {
  padding-left: 3px !important;
}
.filter-input-focused .ant-picker-separator {
  display: inline !important;
}
.filter-input-focused .ant-picker-input {
  padding-left: 3px !important;
}
.ant-picker-range-separator {
  padding: 0 6px 0 0;
}
.ant-select-selection-item {
  padding-left: 3px !important;
}
.total .ant-form-item .ant-input-number-handler-wrap {
  display: none;
}
.total .ant-input-number-input {
  padding: 0 !important;
}
.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.flex-justify-center {
  display: flex;
  justify-content: center;
}
.flex-justify-end {
  display: flex;
  justify-content: flex-end;
}
.flex-align-end {
  display: flex;
  align-items: flex-end;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.flex-column-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex-justify-space-around {
  display: flex;
  justify-content: space-around;
}
.flex-item-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.one-show {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.two-show {
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.ant-picker,
.ant-select,
.ant-picker-input {
  height: 40px;
}
.ant-picker input,
.ant-select input,
.ant-picker-input input {
  font-size: 14px !important;
}
.ant-form-vertical .ant-form-item-label {
  padding-bottom: 16px !important;
  font-weight: 700;
  font-size: 14px !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  box-sizing: border-box;
  margin-top: -3px;
  margin-left: 3px;
  color: #ff4d4f;
  font-size: 14px;
  content: "*";
}
.two-col-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.two-col-form .form-item {
  width: 260px;
}
.two-col-form .form-item-330 {
  width: 330px;
}
.span-form {
  width: 100%;
}
.three-col-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.three-col-form .form-item {
  width: 11.979166666666668vw;
}
.select-people {
  display: flex;
  align-items: center;
  padding-top: 30px;
}
.select-people .ant-select {
  width: 175px !important;
  margin-right: 30px;
}
.drawer-form-content {
  margin-top: 40px;
}
.plan-table-form {
  background: #f5f5f5;
  padding-left: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  flex-wrap: nowrap;
}
.plan-table-form .ant-btn {
  padding: 0;
}
.plan-form-edit {
  margin-top: 4px;
  width: 4.166666666666666vw;
  display: flex;
  margin-left: 10px;
}
.plan-form-edit .ant-btn {
  padding: 0 !important;
  margin-right: 10px;
}
.ant-select-selection-overflow {
  flex-wrap: nowrap;
}
.process-form {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}
.process-form-col-1 {
  width: 100%;
}
.process-form-col-2 {
  width: 40%;
  min-height: 95px;
}
.task-form {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}
.task-form .asset-count .ant-input-number-input-wrap {
  height: 100% !important;
}
.task-form .asset-count .ant-input-number-input-wrap input {
  height: 100% !important;
}
.task-form-col-1 {
  width: 100%;
}
.task-form-col-2 {
  width: 50%;
  min-height: 6.041666666666667vw;
}
.add-edit-description textarea {
  overflow: scroll;
}
.add-edit-description textarea::-webkit-scrollbar {
  display: block;
  width: 3px;
  /* 纵向滚动条*/
}
.add-edit-description textarea::-webkit-scrollbar-track {
  border: 2px solid transparent;
  background-clip: content-box;
  /* THIS IS IMPORTANT */
}
.add-edit-description textarea::-webkit-scrollbar-button {
  height: 0 !important;
  width: 0 !important;
}
.add-edit-description textarea::-webkit-scrollbar-thumb {
  background: #c8c8c8 !important;
  border-radius: 40px;
  width: 50%;
  border: 2px solid transparent;
}
.add-edit-description textarea::-webkit-scrollbar-thumb:horizontal:hover {
  background: #b6b6b6 !important;
}
.header-bar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: #fbfbfc;
  color: black;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  box-sizing: border-box;
  padding: 0 50px 0 50px;
  border-bottom: 2px solid #f2f2f2;
}
.header-tabs {
  display: flex;
  justify-content: space-around;
  min-width: 40vw;
}
.header-tab,
.header-tab-active {
  height: 3.4375000000000004vw;
  line-height: 3.4375000000000004vw;
  display: flex;
  justify-content: center;
  color: black;
  box-sizing: border-box;
}
.header-tab-active {
  color: #29c1b9;
}
.header-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  cursor: pointer;
}
.header-user .ant-avatar {
  cursor: pointer;
}
.header-user .display-flex {
  min-width: 138px;
}
.header-logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 355px;
  min-width: 275px;
  top: 8px;
}
.header-logo img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  margin-right: 12px;
  display: block;
  object-fit: contain;
}
.notice-content {
  max-height: 132px;
  max-width: 294px;
  overflow-y: auto;
}
.notice-content .ant-popover-inner-content {
  padding: 18px 30px;
}
.relative {
  position: relative;
}
.header-notice-number {
  background: #FF4D4F;
  border-radius: 20px;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  padding: 0px 6px;
  position: absolute;
  top: -10px;
  left: 6px;
}
.ant-badge-count {
  background: #29C1B9;
}
.icon-rec svg {
  height: 20px;
  width: 56px;
}
.icon-rec-none {
  margin-right: -4px;
}
.icon-rec-none svg {
  height: 20px;
  width: 56px;
}
.icon-20 svg {
  height: 20px;
  width: 20px;
}
.icon-16 svg {
  height: 16px;
  width: 16px;
}
.icon-18 svg {
  height: 18px;
  width: 18px;
}
.icon-8 svg {
  height: 8px;
  width: 8px;
}
.icon-12 svg {
  height: 12px;
  width: 12px;
}
.icon-14 svg {
  height: 14px;
  width: 14px;
}
.icon-16 svg {
  height: 16px;
  width: 16px;
}
.icon-23 svg {
  height: 23px;
  width: 23px;
}
.icon-24 svg {
  height: 24px;
  width: 24px;
}
.icon-30 svg {
  height: 30px;
  width: 30px;
}
.icon-none {
  display: none;
}
.task-priority-icon svg {
  height: 20px;
  width: 20px;
}
.activity-icon svg {
  width: 48px;
  height: 30px;
}
.icon-small svg {
  width: 16px;
  height: 16px;
}
.icon-32 svg {
  height: 32px;
  width: 32px;
}
.icon-new svg {
  width: 24px;
  height: 12px;
}
.icon-27 svg {
  height: 27px;
  width: 27px;
}
.icon-hover-main:hover {
  color: #29c1b9;
}
.amps-image {
  background: #000;
  z-index: 0;
  position: relative;
  overflow: hidden;
}
.amps-image-mask {
  position: absolute;
  padding: 4px;
  display: flex;
  top: 0;
  z-index: 5;
  left: 0;
  width: 100%;
  justify-content: space-between;
  background-color: #000;
}
.amps-image-mask-item {
  flex: 1;
  justify-content: center;
  position: relative;
  padding: 0 2px;
  display: flex;
}
.amps-image-mask-item::after {
  content: "''";
  right: 0;
  top: 2px;
  position: absolute;
  height: 17px;
  width: 1px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #ffffff50;
}
.amps-image-mask-item:last-child::after {
  display: none;
}
.amps-image-mask-item-span {
  height: 25px;
  flex: 1;
  min-width: 25px;
  max-width: 60px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 2px;
}
.amps-image-mask-item-span:hover {
  background-color: #ffffff15;
}
.amps-image-footer {
  user-select: none;
  z-index: 1;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  padding: 4px;
  align-items: center;
}
.amps-image-footer-score {
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 2px;
  flex: 0 0 28px;
  height: 28px;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #fff;
  margin-right: 6px;
}
.amps-image-footer-title {
  flex: 1;
  line-height: 18px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.amps-image-disabled {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  background: hsla(0, 0%, 100%, 0.4);
}
.amps-image-checked {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
}
.amp-status-1 {
  border: 1px solid #9b9b9b;
}
.amp-status-2 {
  border: 1px solid #29c1b9;
}
.amp-status-3 {
  border: 0.5px solid #fb3152;
}
.amp-status-4 {
  border: 1px solid #bfc5d1;
}
.password-input {
  box-sizing: border-box;
  margin-top: 0.26041666666666663vw;
  margin-bottom: 0.26041666666666663vw;
  border-color: #d9d9d9;
}
.input-sz-l {
  width: 19.791666666666664vw !important;
  height: 2.2916666666666665vw !important;
}
.normal-input,
.input-sz-m,
.input-sz-ml,
.task_plan_input-sz-ml,
.input-sz-xs,
.input-sz-s,
.input-sz-sm,
.date-sz-m,
.input-sz-long,
.select-sz-s,
.select-sz-m,
.select-sz-xs {
  height: 40px!important;
}
.normal-input-borderNone .ant-input {
  border: none !important;
}
.input-sz-m {
  width: 10.9375vw !important;
}
.input-sz-m .ant-input,
.input-sz-m .ant-select {
  width: 10.9375vw !important;
}
.input-sz-ml {
  width: 17.1875vw !important;
}
.input-sz-ml .ant-input,
.input-sz-ml .ant-select {
  width: 17.1875vw !important;
}
.task_plan_input-sz-ml {
  width: 10.9375vw !important;
}
.task_plan_input-sz-ml .ant-input,
.task_plan_input-sz-ml .ant-select {
  width: 10.9375vw !important;
}
.input-sz-xs {
  width: 4.479166666666667vw !important;
}
.input-sz-xs .ant-input,
.input-sz-xs .ant-select {
  width: 4.479166666666667vw !important;
}
.input-sz-s {
  width: 6.770833333333333vw !important;
}
.input-sz-s .ant-input,
.input-sz-s .ant-select {
  width: 6.770833333333333vw !important;
}
.input-sz-sm {
  width: 9.635416666666668vw !important;
}
.date-sz-m {
  width: 11.979166666666668vw !important;
}
.input-sz-long {
  width: 25.156250000000004vw !important;
}
.select-sz-s {
  width: 6.770833333333333vw !important;
}
.select-sz-s .ant-select {
  width: 6.770833333333333vw !important;
}
.select-sz-m {
  width: 10.9375vw !important;
}
.select-sz-m .ant-select {
  width: 10.9375vw !important;
}
.select-sz-xs {
  width: 4.479166666666667vw !important;
}
.select-sz-xs .ant-select {
  width: 4.479166666666667vw !important;
}
.required::after {
  content: "*";
  color: #ff4d4f;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  font-family: SimSun, sans-serif;
  margin-left: 3px;
  line-height: 1;
}
.labeled-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 18.333333333333332vw;
  margin: 15px 15px 15px 0px;
}
.ant-input-disabled {
  cursor: default !important;
  color: black !important;
  background: #f5f5f5 !important;
}
.ant-select-selector {
  padding-left: 8px !important;
}
.ant-select-selection-placeholder {
  padding-left: 3px !important;
}
.se-resizing-bar {
  display: none !important;
}
.site-input-group-wrapper .site-input-split {
  background-color: #fff !important;
  border-radius: 0 !important;
}
.site-input-group-wrapper .site-input-split::placeholder {
  color: black !important;
}
.site-input-group-wrapper .site-input-right {
  border-left-width: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-left: 15px;
}
.site-input-group-wrapper .site-input-left {
  border-right-width: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-left: 15px;
}
.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus,
.site-input-group-wrapper .site-input-right:focus-within {
  border-left-width: 1px;
}
.site-input-group-wrapper .ant-input-rtl.site-input-right {
  border-right-width: 0;
}
.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
  border-right-width: 1px;
}
.site-input-group-wrapper .site-input-left:hover,
.site-input-group-wrapper .site-input-left:focus,
.site-input-group-wrapper .site-input-left:active,
.site-input-group-wrapper .site-input-left:focus-within,
.site-input-group-wrapper .site-input-left:focus-visible {
  z-index: 9;
  border-right-width: 1px;
}
.site-input-group-wrapper .ant-input-rtl.site-input-left {
  z-index: 9;
  border-right-width: 1px;
}
.site-input-group-wrapper .ant-input-rtl.site-input-left:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-left:focus {
  z-index: 9;
  border-right-width: 1px;
}
.amps-mack-icon {
  height: 25px;
  flex: 1;
  min-width: 25px;
  max-width: 60px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 2px;
}
.amps-mack-icon:hover {
  background-color: #ffffff15;
}
.ant-notification-notice {
  padding: 14px !important;
}
.ant-notification-notice-message {
  margin-bottom: 0 !important;
}
.ant-notification-close-x {
  display: none !important;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0 !important;
}
.notification-content {
  width: 240px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.force-logout {
  width: 550px;
  text-align: left;
  padding: 26px 36px 38px 36px;
  box-sizing: border-box;
}
.amps-preview-image-modal {
  top: 0;
  width: 100%;
  padding: 0;
  max-width: 100%;
}
.amps-preview-image {
  user-select: none;
  position: relative;
  height: 100VH;
  width: 100%;
}
.amps-preview-image-operations {
  pointer-events: auto;
  list-style: none;
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  z-index: 2000;
  color: #bbb;
  background: rgba(0, 0, 0, 0.45);
}
.amps-preview-image-operations-operation {
  padding: 10px;
  cursor: pointer;
  margin-left: 10px;
}
.amps-preview-image-operations-operation-disabled {
  pointer-events: none;
  color: #6e6e6e;
}
.amps-preview-image-operations-operation:last-of-type {
  margin-left: 0;
}
.amps-preview-image-operations-icon {
  font-size: 18px;
}
.amps-preview-image-img-wrapper {
  margin: 0 auto;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.amps-preview-image-img-wrapper:before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: "";
}
.amps-preview-image-img {
  user-select: none;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
.amps-preview-image-loading {
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.amps-preview-image-error {
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.amps-preview-image-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.amps-preview-image-switch-left {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  background: rgba(187, 187, 187, 0.45);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  pointer-events: auto;
  cursor: pointer;
  color: #bbb;
}
.amps-preview-image-switch-left-disabled {
  background: rgba(187, 187, 187, 0.3);
  color: #6e6e6e;
  cursor: not-allowed;
}
.amps-preview-image-switch-left-disabled > .anticon {
  cursor: not-allowed;
}
.amps-preview-image-switch-left > .anticon {
  font-size: 24px;
}
.amps-preview-image-switch-right {
  position: absolute;
  right: 10px;
  top: 50%;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  background: rgba(187, 187, 187, 0.45);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  pointer-events: auto;
  color: #bbb;
  cursor: pointer;
}
.amps-preview-image-switch-right-disabled {
  background: rgba(187, 187, 187, 0.2);
  color: #6e6e6e;
  cursor: not-allowed;
}
.amps-preview-image-switch-right-disabled > .anticon {
  cursor: not-allowed;
}
.amps-preview-image-switch-right > .anticon {
  font-size: 24px;
}
.amps-preview-image-desc {
  position: absolute;
  left: 0;
  top: 0;
}
.cascader-default,
.cascader-selected {
  height: 40px;
  display: flex;
  align-items: center;
  margin: 4px 0;
  box-sizing: border-box;
  padding: 0 15px 0 17px;
  cursor: pointer;
  transition: background 0.7s, color 0.3s, cubic-bezier(0.2, 0, 0, 1) 0s;
}
.cascader-default:hover {
  color: #29c1b9;
}
.cascader-selected {
  width: 100%;
  background: rgba(41, 193, 185, 0.08);
  color: #29c1b9;
  justify-content: space-between;
}
.cascader-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.user-checkbox-item {
  width: 100%;
  height: 40px;
  margin: 4px 0;
  padding: 0 0 0 10px;
  line-height: 40px;
  position: relative;
}
.user-checkbox-item .ant-checkbox {
  position: absolute;
  right: 20px;
  top: 12px;
}
.ant-select-multiple .ant-select-selector {
  padding: 0 8px !important;
}
.ant-table-column-has-sorters,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  box-sizing: border-box !important;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}
.ant-table-column-sorters {
  padding-top: 0;
  padding-bottom: 0;
}
.table-row {
  cursor: pointer;
}
.plan-table {
  border: 1px solid #f2f2f2;
  margin-top: 16px;
  border-radius: 4px;
}
.plan-table .ant-table-placeholder {
  display: none;
}
.plan-table .ant-table-thead > tr > th {
  background: transparent;
}
.table-wrap {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.single-record {
  display: flex;
  align-items: center;
  min-height: 56px;
}
.single-record .description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.record-cell {
  box-sizing: border-box;
  padding: 2px;
}
.shooting-plan-detail-table {
  border-right: 1px solid #f0f0f0;
  border-radius: 4px;
}
.shooting-plan-detail-table .ant-table-cell {
  border-right: none !important;
}
.ant-tooltip {
  max-width: 400px !important;
  padding: 8px !important;
  box-sizing: border-box;
}
.ant-tooltip-arrow-content {
  background-color: white !important;
}
.table-cursor-default {
  cursor: default;
}
.table-list-content {
  margin-top: 20px;
  font-size: 14px;
  height: 100%;
}
.record-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
}
.record-list:hover {
  background-color: #fbfbfc;
}
.permissions {
  height: 94vh;
  overflow: auto;
  margin-right: -49px;
  padding-right: 50px;
  box-sizing: border-box;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 12px 50px 12px 20px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.asset-table-row .ant-checkbox {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}
.sortable-table .ant-table-body {
  padding-bottom: 40px;
}
.sortable-table .ant-table .ant-table-header::-webkit-scrollbar {
  width: 0px;
}
.sortable-table .ant-table .ant-table-body::-webkit-scrollbar,
.sortable-table .ant-table .ant-table-body-inner::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.sortable-table .ant-table .ant-table-body::-webkit-scrollbar-thumb,
.sortable-table .ant-table .ant-table-body-inner::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #909090;
}
.sortable-table .ant-table .ant-table-body::-webkit-scrollbar-track,
.sortable-table .ant-table .ant-table-body-inner::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #EDEDED;
}
.sortable-table .ant-table .ant-table-fixed-left .ant-table-body-outer,
.sortable-table .ant-table .ant-table-fixed-right .ant-table-body-outer {
  margin-bottom: 0 !important;
}
.ant-table-selection {
  padding-left: 18px;
}
.asset-table-row td {
  border-bottom: none !important;
}
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #D9D9D9;
}
.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-radius: 4px;
}
.detailsTable table > thead > tr th:first-child,
.detailsTable table > tbody > tr td:first-child {
  padding-left: 50px;
}
.reviewsTable table > tbody > tr > td {
  padding-top: 10px !important;
  padding-bottom: 3px !important;
}
